import { useState, useEffect } from 'react';
import api from '../../api';
import { useAuth } from "../../providers/AuthProvider";
import "./transactions.scss";
import { Loader2, FileText, CheckCircle, XCircle } from "lucide-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PendingTransactions = () => {
  const { authTokens } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterState, setFilterState] = useState("PENDING");
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await api.getPendingTransactions({ token: authTokens.IdToken });
        if (Array.isArray(response.data.items)) {
          setTransactions(response.data.items);
        } else {
          console.error("Expected an array but got:", response.data);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [authTokens]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleFilterChange = (e) => {
    setFilterState(e.target.value);
  };

  const getCountryFromCurrency = (currency) => {
    const currencyCountryMap = {
      NGN: "Nigeria",
      UGX: "Uganda",
      USD: "United States",
      // Add more mappings as needed
    };
    return currencyCountryMap[currency] || "Unknown";
  };

  const formatMoney = (amount, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const matchesSearchQuery = [
      getCountryFromCurrency(transaction.walletCurrency).toLowerCase(),
      transaction.walletCurrency.toLowerCase(),
      transaction.paymentReference.toLowerCase(),
      new Date(transaction.createdOn).toLocaleDateString().toLowerCase(),
      transaction.walletTxnAmount.toString(),
      transaction.id.toString(),
      transaction.status.toLowerCase(),
      transaction.paymentType.toLowerCase(),
    ].some((field) => field.includes(searchQuery));
  
    const matchesFilterState = filterState === "All" || transaction.status === filterState;
  
    return matchesSearchQuery && matchesFilterState;
  });

   // Sort transactions by date (most recent first)
   const sortedTransactions = filteredTransactions.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));


  const openTransactionModal = (transaction) => {
    setSelectedTransaction(transaction);
    setShowTransactionModal(true);
  };

  const closeTransactionModal = () => {
    setShowTransactionModal(false);
    setSelectedTransaction(null);
  };

  const openApprovalModal = (transaction) => {
    setSelectedTransaction(transaction);
    setShowApprovalModal(true);
  };

  const closeApprovalModal = () => {
    setShowApprovalModal(false);
    setSelectedTransaction(null);
  };

  const openRejectionModal = (transaction) => {
    setSelectedTransaction(transaction);
    setShowRejectionModal(true);
  };

  const closeRejectionModal = () => {
    setShowRejectionModal(false);
    setSelectedTransaction(null);
  };

  const approveTransaction = async () => {
    setIsApproving(true);
    try {
      await api.updateTransaction({ token: authTokens.IdToken, transactionId: selectedTransaction.id, status: "APPROVED" });
      const updatedTransaction = { ...selectedTransaction, status: "APPROVED" };
      setTransactions(transactions.map((t) => (t.id === selectedTransaction.id ? updatedTransaction : t)));
      toast.success("Transaction approved successfully!");
    } catch (error) {
      console.error("Error approving transaction:", error);
      const errorMessage = error.response?.data?.errors?.detail || "An error occurred while approving the transaction.";
      toast.error(`Error: ${errorMessage}`);
    } finally {
      setIsApproving(false);
      closeApprovalModal();
    }
  };

  const rejectTransaction = async () => {
    setIsRejecting(true);
    try {
      await api.updateTransaction({ token: authTokens.IdToken, transactionId: selectedTransaction.id, status: "REJECTED" });
      const updatedTransaction = { ...selectedTransaction, status: "REJECTED" };
      setTransactions(transactions.map((t) => (t.id === selectedTransaction.id ? updatedTransaction : t)));
      toast.success("Transaction rejected successfully!");
    } catch (error) {
      console.error("Error rejecting transaction:", error);
      const errorMessage = error.response?.data?.errors?.detail || "An error occurred while rejecting the transaction.";
      toast.error(`Error: ${errorMessage}`);
    } finally {
      setIsRejecting(false);
      closeRejectionModal();
    }
  };

  

  return (
    <div className="transactions_page">
      <div className="heading">
        <div className="title">Pending Transactions</div>
      </div>
      <div className="search_inputs">
        <div className="search_input">
          <div className="input search_query_container">
            <input
              className="search_query"
              type="text"
              placeholder="Search for transactions..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="filter_state">
          <select value={filterState} onChange={handleFilterChange}>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Approved">Approved</option>
            <option value="All">All</option>
          </select>
        </div>
      </div>
      <div className="transactions_table_container">
        {isLoading ? (
          <Loader2 className="loading_spinner" />
        ) : sortedTransactions.length === 0 ? (
          <div className="no_data_message">No transactions to display</div>
        ) : (
          <table className="transactions_table">
            <thead>
              <tr>
                <th>Country</th>
                <th>Currency</th>
                <th>Reference</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Verifiers</th>
                <th>Status</th>
                <th>PayType</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortedTransactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>{getCountryFromCurrency(transaction.walletCurrency)}</td>
                  <td>{transaction.walletCurrency}</td>
                  <td>{transaction.paymentReference}</td>
                  <td>{new Date(transaction.createdOn).toLocaleDateString()}</td>
                  <td>{formatMoney(transaction.walletTxnAmount, transaction.walletCurrency)}</td>
                  <td>{transaction.numberOfApprovers}</td>
                  <td>{transaction.status}</td>
                  <td>{transaction.paymentType}</td>
                  <td>
                    <div className="transaction_controls">
                      <FileText
                        className="view_transaction user_control"
                        onClick={() => openTransactionModal(transaction)}
                      />
                      {transaction.status === "PENDING" && (
                        <>
                          <button className="approve_button" onClick={() => openApprovalModal(transaction)}>
                            <CheckCircle size={13} className="icon" /> Approve
                          </button>
                          <button className="reject_button" onClick={() => openRejectionModal(transaction)}>
                            <XCircle size={13} className="icon" /> Reject
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {showTransactionModal && (
        <div className="transaction_modal">
          <div className="transaction_modal_content">
            <h2>Transaction Details</h2>
            <div className="transaction_detail">
              <span className="transaction_label">Date:</span>
              <span className="transaction_value">{selectedTransaction.createdOn}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Transaction Type:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnType}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Amount:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnAmount}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Status:</span>
              <span className="transaction_value">{selectedTransaction.status}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Account Number:</span>
              <span className="transaction_value">{selectedTransaction.profileId}</span>
            </div>
            <div className="modal_buttons centered_buttons">
              <button onClick={closeTransactionModal}> <XCircle className="icon" /> Close</button>
            </div>
          </div>
        </div>
      )}
      {showApprovalModal && (
        <div className="approval_modal">
          <div className="approval_modal_content">
            <h2>Approve Transaction</h2>
            <p>Are you sure you want to approve this transaction?</p>
            <div className="transaction_detail">
              <span className="transaction_label">Date:</span>
              <span className="transaction_value">{selectedTransaction.createdOn}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Transaction Type:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnType}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Amount:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnAmount}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Status:</span>
              <span className="transaction_value">{selectedTransaction.status}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Account Number:</span>
              <span className="transaction_value">{selectedTransaction.profileId}</span>
            </div>
            <div className="modal_buttons">
              <button onClick={approveTransaction} disabled={isApproving}>
                {isApproving ? <Loader2 className="icon" /> : <CheckCircle className="icon" />} Approve
              </button>
              <button className='cancel_button' onClick={closeApprovalModal} disabled={isApproving}>
                <XCircle className="icon" /> Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showRejectionModal && (
        <div className="rejection_modal">
          <div className="rejection_modal_content">
            <h2>Reject Transaction</h2>
            <p>Are you sure you want to reject this transaction?</p>
            <div className="transaction_detail">
              <span className="transaction_label">Date:</span>
              <span className="transaction_value">{selectedTransaction.createdOn}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Transaction Type:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnType}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Amount:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnAmount}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Status:</span>
              <span className="transaction_value">{selectedTransaction.status}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Account Number:</span>
              <span className="transaction_value">{selectedTransaction.profileId}</span>
            </div>
            <div className="modal_buttons">
              <button onClick={rejectTransaction} disabled={isRejecting}>
                {isRejecting ? <Loader2 className="icon" /> : <XCircle className="icon" />} Reject
              </button>
              <button className='cancel_button' onClick={closeRejectionModal} disabled={isRejecting}>
                <XCircle className="icon" /> Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default PendingTransactions;