import "./createCompany.scss";
import { useNavigate } from "react-router-dom";
import { Loader2, Info } from "lucide-react";
import { useForm } from "react-hook-form";
import bellIcon from '../../assets/notification_icon.svg';
import { useAuth } from "../../providers/AuthProvider";
import cn from "classnames";
import api from "../../api";
import { useMutation } from "@tanstack/react-query";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateCompany = () => {
    const { authTokens } = useAuth();
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    const navigate = useNavigate();

    const createCompany = useMutation({
        mutationFn: (details) => {
          return api.addCompany({ token: authTokens.IdToken, companyData: details });
        },
        onSuccess: () => {
          toast.success("Company created successfully!");
          navigate("/companies");
        },
        onError: (error) => {
          const errorMessage = error.response?.data?.errors?.errors?.[0]?.detail || "Failed to create company";
          toast.error(`Error: ${errorMessage}`);
        }
    });

    const onNext = (details) => {
        // Set password values manually
        details.password = "Kampala123?";
        details.confirmPassword = "Kampala123?";
        createCompany.mutate(details);
    };

    return (
        <div className="create_company_account_page">
            <div className="create_company_account_header">
                <div className="create_company_account_heading">
                    Create a company account
                </div>
                <div className="bellIcon">
                    <img src={bellIcon} alt="" />
                </div>
            </div>
            <div className="create_company_account_sub_heading">
                Setup a company account to operate company funds, trade and make transfers on Muda. Company account allows to manage funds by teams with established roles and scopes of responsibilities.
            </div>
            <div className="create_company_account_body">
                <div className="create_company_account_form">
                    <div className="form_leftside">
                        <label className="create_acct_input">
                            <span className="create_acct_input_label">
                                First Name <span className="required">*</span>
                            </span>
                            <input 
                                placeholder="First Name"
                                name="firstName"
                                className={cn({ error: errors.firstName?.type == "required" })}
                                {...register("firstName", { required: true })}
                            />
                            {errors.firstName?.type === "required" && (
                                <span className="create_acct_input_error">
                                <Info className="signup_input_error_icon" /> Please fill in
                                your first name
                                </span>
                            )}
                        </label>
                        <div className="spacer"></div>
                        <label className="create_acct_input">
                            <span className="create_acct_input_label">
                                Last Name <span className="required">*</span>
                            </span>
                            <input
                                placeholder="Last Name"
                                name="lastName" 
                                className={cn({ error: errors.lastName?.type == "required" })}
                                {...register("lastName", { required: true })}
                            />  
                            {errors.lastName?.type === "required" && (
                                <span className="create_acct_input_error">
                                <Info className="signup_input_error_icon" /> Please fill in
                                your last name
                                </span>
                            )}
                        </label>
                        <div className="spacer"></div>
                        <label className="create_acct_input">
                            <span className="create_acct_input_label">
                                Email <span className="required">*</span>
                            </span>
                            <input
                                placeholder="Email"
                                name="email"
                                className={cn({ error: errors.email?.type == "required" })}
                                {...register("email", { required: true })}
                            />  
                            {errors.email?.type === "required" && (
                                <span className="create_acct_input_error">
                                <Info className="signup_input_error_icon" /> Please fill in
                                your email
                                </span>
                            )}
                        </label>
                    </div>
                    <div className="form_rightside">
                        <label className="create_acct_input">
                            <span className="create_acct_input_label">
                                Company Name <span className="required">*</span>
                            </span>
                            <input
                                placeholder="Company Name"
                                name="companyName"
                                className={cn({ error: errors.companyName?.type == "required" })}
                                {...register("companyName", { required: true })}
                            />
                            {errors.companyName?.type === "required" && (
                                <span className="create_acct_input_error">
                                    <Info className="signup_input_error_icon" /> Please fill in
                                    your company name
                                </span>
                            )}
                        </label>
                        <div className="spacer"></div>
                        <label className="create_acct_input">
                            <span className="create_acct_input_label">
                                Company Registration Number <span className="required">*</span>
                            </span>
                            <input
                                placeholder="Company Registration Number"
                                name="companyRegNumber"
                                className={cn({ error: errors.companyRegNumber?.type == "required" })}
                                {...register("companyRegNumber", { required: true })}
                            />
                            {errors.companyRegNumber?.type === "required" && (
                                <span className="create_acct_input_error">
                                    <Info className="signup_input_error_icon" /> Please fill in
                                    your company registration number
                                </span>
                            )}
                        </label>
                        <div className="spacer"></div>
                        <label className="create_acct_input">
                            <span className="create_acct_input_label">
                                Domain
                            </span>
                            <input
                                placeholder="Domain"
                                name="domain"
                                {...register("domain")}
                            />
                        </label>
                    </div>
                </div>
                <div className="create_acct_btn_box">
                    <div
                        className={cn("next_btn", { is_creating_company: createCompany.isPending })}
                        onClick={handleSubmit(onNext)}>
                        {createCompany.isPending && <Loader2 className="createCompany_loader" />}
                        {!createCompany.isPending && <span>Submit</span>}
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};

export default CreateCompany;