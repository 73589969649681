import { useParams } from "react-router-dom";
import "./companies.scss";

const CompanyDetails = () => {
  const { id } = useParams();

  // Mocked company data (replace with an API call for dynamic data)
  const company = {
    id,
    name: "Company A",
    country: "United Kingdom",
    city: "London",
    address: "Chesterfield avenue, 193G/5",
    zipCode: "SE17 9SE",
    team: [
      {
        name: "Sarah Melbourne",
        email: "sarah.melbourne@gmail.com",
        role: "Admin / Checker",
        status: "Active",
      },
      {
        name: "Meleh Saint",
        email: "meleh.saint@gmail.com",
        role: "Maker",
        status: "Inactive",
      },
      {
        name: "John McKean",
        email: "john.smith@gmail.com",
        role: "Treasurer",
        status: "Pending",
      },
    ],
  };

  return (
    <div className="company_details_page">
      <div className="company_details_header">
        <h2>{company.name}</h2>
        <button>Company KYB</button>
      </div>
      <form className="company_details_form">
        <div className="form_group">
          <label>Company Name</label>
          <input type="text" value={company.name} disabled />
        </div>
        <div className="form_group">
          <label>Country</label>
          <input type="text" value={company.country} disabled />
        </div>
        <div className="form_group">
          <label>City</label>
          <input type="text" value={company.city} disabled />
        </div>
        <div className="form_group">
          <label>Street Address</label>
          <input type="text" value={company.address} disabled />
        </div>
        <div className="form_group">
          <label>ZIP/Postal Code</label>{" "}
          <input type="text" value={company.zipCode} disabled />
        </div>
        <div className="form_actions">
          <button type="button">Cancel</button>
          <button type="button">Save</button>
        </div>
      </form>
      <h3>Team</h3>
      <table className="team_table">
        <thead>
          <tr>
            td
            <th>User Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {company.team.map((member, index) => (
            <tr key={index}>
              <td>{member.name}</td>
              <td>{member.email}</td>
              <td>{member.role}</td>
              <td className={member.status.toLowerCase()}>{member.status}</td>
              <td>
                <button>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="delete_account_button">Delete company account</button>
    </div>
  );
};

export default CompanyDetails;
