/* 
Nested routes added as children to workspace route
ref : https://reactrouter.com/en/main/start/tutorial#nested-routes
*/
import CurrencyPrices from "./buyandsell/currencyprices/currencyprices";
import Fees from "./buyandsell/fees/fees";
import Limits from "./buyandsell/limits/limits";
import PairPrices from "./buyandsell/pairprices/pairprices";
import Dashboard from "./dashboard/dashboard";
import EscrowCompleted from "./escrow/completed/escrowCompleted";
import EscrowPending from "./escrow/pending/escrowPending";
import EscrowedRequest from "./escrow/request/escrowedRequest";
import Reports from "./reports/reports";
import Settings from "./settings/settings";
import ApprovalSettings from "./settings/approvalSettings";
import ClientUsers from "./users/client";
import AdminUsers from "./users/admin";
import Transactions from "./transactions/transactions";
import PendingTransactions from "./transactions/pending";
import CreateCompany from "./create_company_account/createCompany";
import Companies from "./companies/companies";
import CompanyDetails from "./companies/companyDetails";

const workspaceRoutes = [
	{ index: true, element: <Dashboard /> },

	{
		path: "dashboard",
		element: <Dashboard />,
	},
	{
		path: "reports",
		element: <Reports />,
	},
	{
		path: "settings",
		element: <Settings />,
	},
	{
		path: "settings/approval-settings",
		element: <ApprovalSettings />,
	},
	{
		path: "escrow/pending",
		element: <EscrowPending />,
	},
	{
		path: "escrow/completed",
		element: <EscrowCompleted />,
	},
	{
		path: "escrow/pending/request/:activeRequestId",
		element: <EscrowedRequest />,
	},
	{
		path: "escrow/completed/request/:activeRequestId",
		element: <EscrowedRequest />,
	},
	{
		path: "buyandsell/currencyprices",
		element: <CurrencyPrices />,
	},
	{
		path: "buyandsell/pairprices",
		element: <PairPrices />,
	},
	{
		path: "buyandsell/fees",
		element: <Fees />,
	},
	{
		path: "buyandsell/limits",
		element: <Limits />,
	},
	{
		path: "users/clients",
		element: <ClientUsers />,
	},
	{
		path: "users/admin",
		element: <AdminUsers />,
	},
	{
		path: "transactions/pending",
		element: <PendingTransactions />,
	},
	{
		path: "transactions/completed",
		element: <Transactions />,
	},
	{
		path: "company/create",
		element: <CreateCompany />,
	},
	{
		path: "companies",
		element: <Companies />,
	},
	{
		path: "companies/:id",
        element: <CompanyDetails />, 
	},
];

export default workspaceRoutes;
