import cn from "classnames";
import { Loader2, RefreshCw } from "lucide-react";
import "./escrowPending.scss";
import { useEscrowPending } from "./escrowPendingProvider";
import { formatAmount } from "../../../common/formatAmount";
import moment from "moment";
import _ from "lodash";
import Big from "big.js";
import { ESCROWED_REQUEST_ACTIONS, useEscrowedRequestDispatch } from "../request/escrowedRequestProvider";
import { useNavigate } from "react-router-dom";

const EscrowPending = () => {
	const navigate = useNavigate();
	const { isFetchingEscrowPending, requests } = useEscrowPending();
	const escrowedRequestDispatch = useEscrowedRequestDispatch();

	const loadingPage = () => {
		return isFetchingEscrowPending;
	};

	const reloadPage = async () => {
		//refresh escrow pending
	};

	const viewRequest = ({ request }) => {
		escrowedRequestDispatch({
			type: ESCROWED_REQUEST_ACTIONS.updateStates,
			states: [
				{ key: "back", value: "/escrow/pending" },
				{ key: "activeRequest", value: request },
				{ key: "activeRequestId", value: request.id },
			],
		});
		navigate(`/escrow/pending/request/${request.id}`);
	};

	const getExchangeRate = ({ request }) => {
		const sellCurrency = request.order.sellCurrency;
		const buyCurrency = request.order.buyCurrency;
		const buyRate = formatAmount(request.order.volumeTypeAmount);
		const sellRate = formatAmount(Big(1).div(Big(request.order.volumeTypeAmount)).toString());
		return (
			<>
				<p>
					1 {sellCurrency} = {buyRate} {buyCurrency}
				</p>
				<p>
					1 {buyCurrency} = {sellRate} {sellCurrency}
				</p>
			</>
		);
	};

	const getSelling = ({ request }) => {
		const currency = request.order.sellCurrency;
		const rate = request.order.volumeTypeAmount;
		let amount;
		if (!_.isEmpty(`${request.desiredPurchaseAmount}`) && !_.isEmpty(rate)) {
			amount = formatAmount(Big(request.desiredPurchaseAmount).div(Big(rate)).toString());
		}
		const selling = `${amount} ${currency}`;
		return <p>{selling}</p>;
	};

	const getBuying = ({ request }) => {
		const currency = request.order.buyCurrency;
		const amount = formatAmount(request.desiredPurchaseAmount);
		const buying = `${amount} ${currency}`;
		return <p>{buying}</p>;
	};

	return (
		<div id="escrow_pending_page">
			<div className="heading">
				<div className="title">Pending Escrow Orders</div>
				<RefreshCw onClick={reloadPage} className={cn("refresh_icon", { rotating: loadingPage() })} />
			</div>

			{loadingPage() && (
				<div className="escrow_pending_loader_container">
					Loading pending orders...
					<Loader2 className="escrow_pending_loader" />
				</div>
			)}

			{!loadingPage() && (
				<table className="escrow_pending_table transactions_table">
					<thead>
						<tr>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Selling</th>
							<th>Buying</th>
							<th>Seller</th>
							<th>Buyer</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{requests.map((request) => (
							<tr key={request.id} onClick={() => viewRequest({ request })}>
								<td>
									{request.order.buyCurrency} - {request.order.sellCurrency}
								</td>
								<td>{getExchangeRate({ request })}</td>
								<td>{getSelling({ request })}</td>
								<td>{getBuying({ request })}</td>
								<td>{request.order.createdByName}</td>
								<td>{request.createdByName}</td>
								<td>{moment(request.createdAt).format("DD/MM/YYYY - h:mm A")}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default EscrowPending;
