import "./escrowCompleted.scss"

const EscrowCompleted = () => {
    return (
		<div className="escrow_completed_page">
			<div className="heading">
				<div className="title">Escrow Completed</div>
			</div>
		</div>
	);
}
export default EscrowCompleted;