/* eslint-disable react/prop-types */
import { EscrowPendingProvider } from "../pending/escrowPendingProvider";
import { EscrowedRequestProvider } from "../request/escrowedRequestProvider";

const EscrowProviders = ({ children }) => {
	return (
		<EscrowPendingProvider>
			<EscrowedRequestProvider>{children}</EscrowedRequestProvider>
		</EscrowPendingProvider>
	);
};

export default EscrowProviders;
