import "./approval.scss";
import { Pencil, Check, X, Loader2 } from "lucide-react";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApprovalSettings = () => {
  const [settings, setSettings] = useState([
    { id: 1, transactionType: "Credit", approvers: 2, minAmount: 1000, maxAmount: 10000 },
    { id: 2, transactionType: "Debit", approvers: 3, minAmount: 500, maxAmount: 5000 },
    { id: 3, transactionType: "Transfer", approvers: 1, minAmount: 2000, maxAmount: 20000 },
  ]);
  const [editedSettings, setEditedSettings] = useState({});
  const [isSavingSetting, setIsSavingSetting] = useState(false);

  useEffect(() => {
    const newEditedSettings = {};
    settings.forEach((setting) => {
      newEditedSettings[setting.id] = { ...setting, editing: false, saving: false };
    });
    setEditedSettings(newEditedSettings);
  }, [settings]);

  const editSetting = (setting) => {
    const editedSetting = editedSettings[setting.id];
    editedSetting.editing = true;
    setEditedSettings({
      ...editedSettings,
      [setting.id]: editedSetting,
    });
  };

  const editingSetting = (setting) => {
    return editedSettings[setting.id] && editedSettings[setting.id].editing;
  };

  const savingSetting = (setting) => {
    return editedSettings[setting.id] && editedSettings[setting.id].saving;
  };

  const cancelEditSetting = (setting) => {
    const oldSetting = settings.find((s) => s.id === setting.id);
    setEditedSettings({
      ...editedSettings,
      [setting.id]: { ...oldSetting, editing: false, saving: false },
    });
  };

  const updateSetting = (setting) => {
    const editedSetting = editedSettings[setting.id];
    setIsSavingSetting(true);
    // Simulate saving setting
    setTimeout(() => {
      setSettings(settings.map((s) => (s.id === setting.id ? editedSetting : s)));
      setIsSavingSetting(false);
      setEditedSettings({
        ...editedSettings,
        [setting.id]: { ...editedSetting, editing: false, saving: false },
      });
      toast.success("Setting updated successfully!");
    }, 1000);
  };

  const handleInputChange = (setting, field, value) => {
    const editedSetting = editedSettings[setting.id];
    editedSetting[field] = value;
    setEditedSettings({
      ...editedSettings,
      [setting.id]: editedSetting,
    });
  };

  return (
    <div className="approval_settings_page">
      <div className="heading">
        <div className="title">Approval Settings</div>
      </div>
      <div className="settings_table_container">
        <table className="settings_table">
          <thead>
            <tr>
              <th>Transaction Type</th>
              <th>Approvers</th>
              <th>Minimum Amount</th>
              <th>Maximum Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {settings.map((setting) => (
              <tr key={setting.id}>
                <td>
                  {!editingSetting(setting) ? (
                    setting.transactionType
                  ) : (
                    <input
                      type="text"
                      value={editedSettings[setting.id].transactionType}
                      onChange={(e) =>
                        handleInputChange(setting, "transactionType", e.target.value)
                      }
                    />
                  )}
                </td>
                <td>
                  {!editingSetting(setting) ? (
                    setting.approvers
                  ) : (
                    <input
                      type="number"
                      value={editedSettings[setting.id].approvers}
                      onChange={(e) =>
                        handleInputChange(setting, "approvers", e.target.value)
                      }
                    />
                  )}
                </td>
                <td>
                  {!editingSetting(setting) ? (
                    setting.minAmount
                  ) : (
                    <input
                      type="number"
                      value={editedSettings[setting.id].minAmount}
                      onChange={(e) =>
                        handleInputChange(setting, "minAmount", e.target.value)
                      }
                    />
                  )}
                </td>
                <td>
                  {!editingSetting(setting) ? (
                    setting.maxAmount
                  ) : (
                    <input
                      type="number"
                      value={editedSettings[setting.id].maxAmount}
                      onChange={(e) =>
                        handleInputChange(setting, "maxAmount", e.target.value)
                      }
                    />
                  )}
                </td>
                <td>
                  <div className="setting_controls">
                    {!editingSetting(setting) && !savingSetting(setting) && (
                      <Pencil
                        className="edit setting_control"
                        onClick={() => editSetting(setting)}
                      />
                    )}
                    {editingSetting(setting) && !savingSetting(setting) && (
                      <>
                        <Check
                          className="save_edit setting_control"
                          onClick={() => updateSetting(setting)}
                        />
                        <X
                          className="cancel_edit setting_control"
                          onClick={() => cancelEditSetting(setting)}
                        />
                      </>
                    )}
                    {savingSetting(setting) && (
                      <Loader2 className="saving_setting_loader setting_control" />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ApprovalSettings;